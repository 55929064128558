.header {
  text-align: center;
}

.welcome {
  text-align: center;
  margin: auto;
  padding: 1rem;
}

.img-bitpoint {
  max-width: 250px !important;
  margin: auto;
  display: flex;
  padding: 1rem;
}
.img-warning {
  max-width: 250px !important;
  margin: auto;
  display: flex;
  padding: 1rem;
}

.modal-content {
  height: 100%;
}

.continue {
  width: 100%;
}

.btn {
  border-radius: 5rem !important;
}
.progress-bar {
  background-color: #47ccff !important;
}
.red-text {
  color: red !important;
}
.img-loading {
  margin: auto;
  display: flex;
  width: 70px;
}
.title-signup {
  color: #00b9fe;
  font-weight: 700;
  margin-bottom: 15px;
  margin: auto;
}
.md-2 {
  max-width: 20% !important;
}
.md-4 {
  max-width: 100% !important;
}
.text-red {
  color: red;
}
.bad-input {
  border: 1px solid red !important;
}

.card-custom-sumary {
  border-radius: 1.1rem !important ;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
